// Extending Inspinia template with our own style

// General
.btn {
  text-transform: uppercase;
}

// Flot chart
.flot-chart .legendLabel, .flot-chart-legend .legendLabel {
  padding-right: 10px;
}

// Kanban board
#kanban-board .section-head {
  height: 52px;
}
#kanban-board .section-content {
  height: calc(100% - 52px);
}

.notification-ibox {
  background: white;
  width: 60%;
}

.sub-ibox {
  max-width: 600px;
}

.sub-ibox .ibox-content {
  border: 0;

}

.ibox-title {
  border-bottom: 2px solid #f3f3f4;
  margin: 0 0 10px 0;
}


.btn-md {
  padding: 5px 7px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.ibox-title p {
  font-size: 20px;
  margin: 0;
}

#client-notifications-table > thead > tr > th {
  border: 1px solid white;
}

.table > .notification-tbody > tr > th {
  border: 0;
}

.table > .notification-tbody > tr > th:last-child  {
  display: flex;
  justify-content: space-between;
}


.vertical-menu {
  width: 35px;
  height: 100%;
  border-radius: 5px;
  background-color: #eee;
}

.vertical-menu p {
  display: block;
  padding: 5px 0;
  margin: 0 !important;
  text-align: center;
}

.vertical-menu p:last-child {
  padding-top: 55px;
}

.vertical-menu p:hover {
  background-color: #ccc;
}

.task_status_main {
  border: 1px solid #eee;
  margin-left: 15px;
}

.status-edit {
  align-self: center;
  text-align: end;
}

#editNotificationModalLabel {
  text-align: center;
  font-weight: 600;
}

#notification-body {
  padding: 10px 20px;
  border: 1px solid #eee;
}

#notification-body > div {
  width: 40.5%;
  padding: 10px;
  background: #eee;
  border-radius: 10px;
}

#notification-body > div > p {
  margin: 0;
}

#statuses > span {
  font-size: 16px;
}

.shortcode {
  background: grey;
  padding: 100px;
}

.status-actions {
  display:flex;
}

@media (max-width: 1268px) {
  .notification-ibox {
    width: 90%;
  }
}